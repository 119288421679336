import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_OFFERS, ADMIN_OFFERS_MANAGE, ADMIN_OFFER_USPS_MANAGE } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import Page from 'Components/layout/panel/Page';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/admin/offerUsps/Editor';
import { TAB_OFFERS } from 'Components/pages/admin/Offers/component';
import { TAB_USPS_LIST } from 'Components/pages/admin/OffersManage/component';

export const TAB_DATA = 'data';

export default class AdminUspsProductsManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        offerUsp: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };
    static defaultProps = {};
    state = {};

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.single({ id: params.id });
    }

    render() {
        const { offerUsp, location, history } = this.props;

        return (
            <StyledComponent
                className="admin-offer-usps-manage"
                styles={require('./styles')}
            >
                <Page
                    elementMode={true}
                    data={offerUsp}
                >
                    {offerUsp && offerUsp.data && (
                        <PageContent
                            breadcrumbs={[{
                                to: withVariables(ADMIN_OFFERS.path, {}, { tab: TAB_OFFERS }),
                                label: 'Oferty',
                            }, {
                                to: withVariables(ADMIN_OFFERS_MANAGE.path, { id: offerUsp.data.offer.id }, { tab: TAB_USPS_LIST }),
                                label: `Edytuj ofertę ${offerUsp.data.offer.name}`,
                            }, {
                                to: withVariables(ADMIN_OFFER_USPS_MANAGE.path, { id: offerUsp.data.id }),
                                label: 'Edytuj USP zamówienia',
                            }]}
                        >
                            <TabNavigation
                                location={location}
                                history={history}
                                headline="Edytuj USP zamówienia"
                                subHeadline={offerUsp.data.name}
                                tabs={[{
                                    key: TAB_DATA,
                                    label: 'Dane',
                                    children: (
                                        <Editor
                                            location={location}
                                            history={history}
                                            data={offerUsp.data}
                                        />
                                    ),
                                }]}
                            />
                        </PageContent>
                    )}
                </Page>
            </StyledComponent>
        );
    }
}